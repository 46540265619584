<template>
 <v-container
 id="visual"
 >
 <v-alert
    :type="alertType"
    v-model="alertValue"
    dense
    text
    dismissible
  >
  {{ alertMsg }}
  </v-alert>
 <v-card
 class="rounded-lg"
 >
    <v-row
    align="center"
    justify="center"
    >
      <v-col
      lg="8"
      md="12"
      sm="12"
      >
        <div id="wrapper">
          <v-select
          id="subgraph"
          v-model="selectedOption"
          :items="subGraphOptions"
          label="Mapping Pair"
          dense
          style="max-width: 90%;"
          >
          </v-select>
          <v-btn
          id="refresh"
          ref="refreshBtn"
          icon
          color="primary"
          dark
          @click="getMapping"
          >
        <v-icon>
          mdi-autorenew
        </v-icon>
        </v-btn>
        </div>
      </v-col>
      <!-- <div id="wrapper"> -->
      <v-col
      lg="4"
      md="12"
      sm="12"
      >
      <v-spacer></v-spacer>
      <!-- </div> -->
      <!-- <v-spacer></v-spacer> -->
      <!-- <div id="wrapper"> -->
      <div
      class="text-center"
      >
        <v-btn
        @click="refreshGraph"
        color="primary"
        rounded
        >
        Visualize
        </v-btn>
        <v-btn
        @click="downloadImage"
        color="primary"
        rounded
        >
        Save Image
        </v-btn>
      </div>
      </v-col>
      <!-- </div> -->
    </v-row>
    </v-card>
    <v-card
    class="rounded-lg"
    :loading="loading"
    >
      <div id="kgCanvas" />
    </v-card>
  </v-container>
</template>

<script>
  import G6 from '@antv/g6'
  import api from '@/utils/api.js'
  var graph
  const tooltip = new G6.Tooltip({
    offsetX: 10,
    offsetY: 10,
    // the types of items that allow the tooltip show up
    // 允许出现 tooltip 的 item 类型
    trigger: 'click',
    itemTypes: ['node'],
    // custom the tooltip's content
    // 自定义 tooltip 内容
    getContent: (e) => {
      const outDiv = document.createElement('div')
      outDiv.style.width = 'fit-content'
      // outDiv.style.padding = '0px 0px 20px 0px';
      var attrContent = '<h4>Attributes</h4><ul>'
      const model = e.item.getModel()
      for (const key in model.attribute) {
        const value = model.attribute[key]
        attrContent += `<li>${key}: ${value}</li>`
      }
      attrContent += '</ul>'
      outDiv.innerHTML = attrContent
      return outDiv
    },
  })
  export default {
    //
    graph,
    tooltip,
    data () {
      return {
        loading: false,
        userId: null,
        alertType: 'success',
        alertValue: false,
        alertMsg: '',
        rawData: {},
        mappingData: {},
        subGraphNums: 0,
        subGraphOptions: [],
        subGraphOptionDict: {},
        selectedOption: undefined,
        colorSubjMap: {
          sourceKGSubj: '#0000FF',
          targetKGSubj: '#FF1493',
          eqvEdgeSubj: '#FFA500',
        },
        colorMap: {},
        sourceGroupTag: 'sourceKG',
        targetGroupTag: 'targetKG',
      }
    },
    created () {
      this.userId = localStorage.getItem('taskId')
    },
    mounted () {
      const container = document.getElementById('kgCanvas')
      const width = container.scrollWidth
      const height = container.scrollHeight || 500
      this.graph = new G6.Graph({
        container: 'kgCanvas',
        width: width,
        height: height,
        animate: false,
        fitCenter: true,
        linkCenter: true,
        fitView: true,
        plugins: [tooltip],
        layout: {
          type: 'force',
          linkDistance: 500,
          preventOverlap: true,
          clustering: true,
          // clusterNodeStrength: 8,
          // clusterEdgeDistance: 300,
          clusterNodeSize: 50,
          clusterFociStrength: 1.5,
          nodeSpacing: 5,
        },
        modes: {
          default: ['zoom-canvas', 'drag-canvas', 'drag-node'],
        },
        defaultNode: {
          size: [30, 30],
          labelCfg: {
            style: {
              fontSize: 10,
            },
          },
        },
        defaultEdge: {
          type: 'quadratic',
          style: {
            endArrow: {
              path: 'M 25,0 L 30,2 L 30,-2 Z',
              fill: '#e2e2e2',
            },
          },
          labelCfg: {
            autoRotate: true,
            style: {
              fontSize: 8,
            },
          },
        },
      })
      this.initGraph()
      window.onresize = () => {
        if (!this.graph || this.graph.get('destroyed')) return
        if (!container || !container.clientWidth || !container.clientHeight) return
        this.graph.changeSize(container.clientWidth, container.clientHeight)
      }
      const backColor = '#fff'
      const theme = 'default'
      const disableColor = '#777'
      const colorSets = G6.Util.getColorSetsBySubjectColors(
        Object.values(this.colorSubjMap),
        backColor,
        theme,
        disableColor,
      )
      const keys = Object.keys(this.colorSubjMap)
      for (var keyIndex in keys) {
        this.colorMap[keys[keyIndex]] = colorSets[keyIndex]
      }
      this.getMapping()
    },

    methods: {
      getMapping () {
        this.loading = true
        api.getMappings(this.userId).then((res) => {
          if (res.status === 200) {
            const success = res.data.success
            if (success) {
              this.rawData = res.data
              this.rawDataHandler()
            } else {
              this.alertType = 'error'
              this.alertMsg = 'ERROR: ' + res.data.message
              this.alertValue = true
            }
          } else {
            this.alertType = 'error'
            this.alertMsg = 'ERROR: Fail to get response from server.'
            this.alertValue = true
          }
          this.loading = false
        })
      },
      rawDataHandler () {
        this.subGraphNums = this.rawData.data.subGraphs.subGraphNum
        this.subGraphOptionDict = {}
        this.subGraphOptions = []
        const nodeNameKey1 = 'centerNodeName-1'
        const nodeNameKey2 = 'centerNodeName-2'
        for (let i = 1; i <= this.subGraphNums; ++i) {
          const subGraphKey = 'subGraph-' + i.toString()
          const subGraphData = this.rawData.data.subGraphs[subGraphKey]
          const optionName = subGraphData[nodeNameKey1] + ' ⇌ ' + subGraphData[nodeNameKey2]
          this.subGraphOptionDict[optionName] = subGraphKey
          this.subGraphOptions.push(optionName)
        }
        this.mappingData = {}
        for (let i = 1; i <= this.subGraphNums; ++i) {
          const subGraphKey = 'subGraph-' + i.toString()
          this.mappingData[subGraphKey] = this.generateVisData(subGraphKey)
        }
      },
      refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      },
      generateVisData (subGraphKey) {
        var graphData = {
          nodes: [],
          edges: [],
        }
        const mappingNodes = this.rawData.data.subGraphs[subGraphKey].nodes
        const mappingEdges = this.rawData.data.subGraphs[subGraphKey].edges
        graphData.nodes = mappingNodes.map(function (node) {
          return Object.assign({}, {
            id: node.nodeId,
            label: node.nodeName,
            groupId: node.group,
            attribute: node.attributes,
          })
        })
        const sourceNodeList = mappingNodes.map((node) => {
          if (node.group === this.sourceGroupTag) {
            return node.nodeId
          }
        })
        const colorMap = this.colorMap
        const centerNode1 = this.rawData.data.subGraphs[subGraphKey]['centerNodeId-1']
        const centerNode2 = this.rawData.data.subGraphs[subGraphKey]['centerNodeId-2']
        const kgEdges = mappingEdges.map(function (edge) {
          let edgeColorSubj, edgeColor
          if (edge.sourceNodeId === centerNode1 || edge.targetNodeId === centerNode1) {
            edgeColorSubj = colorMap.sourceKGSubj
            edgeColor = colorMap.sourceKGSubj.edgeHighlightStroke
          } else if (edge.sourceNodeId === centerNode2 || edge.targetNodeId === centerNode2) {
            edgeColorSubj = colorMap.targetKGSubj
            edgeColor = colorMap.targetKGSubj.edgeHighlightStroke
          } else {
            if (sourceNodeList.includes(edge.sourceNodeId)) {
              edgeColorSubj = colorMap.sourceKGSubj
              edgeColor = colorMap.sourceKGSubj.mainFill
            } else {
              edgeColorSubj = colorMap.targetKGSubj
              edgeColor = colorMap.targetKGSubj.mainFill
            }
          }
          const newStyle = Object.assign(edge.style || {}, {
            endArrow: {
              path: 'M 25,0 L 30,2 L 30,-2 Z',
              stroke: edgeColor,
              fill: edgeColor,
            },
            stroke: edgeColor,
          })
          const newStateStyle = Object.assign(edge.stateStyles || {}, {
            active: {
              stroke: edgeColorSubj.activeStroke,
              shadowColor: edgeColorSubj.activeStroke,
            },
            inactive: {
              fill: edgeColorSubj.inactiveFill,
              stroke: edgeColorSubj.inactiveStroke,
            },
            selected: {
              stroke: edgeColorSubj.selectedStroke,
              shadowColor: edgeColorSubj.selectedStroke,
            },
            highlight: {
              stroke: edgeColorSubj.highlightStroke,
            },
            disable: {
              stroke: edgeColorSubj.disableStroke,
            },
          })
          return Object.assign({}, {
            source: edge.sourceNodeId,
            target: edge.targetNodeId,
            label: edge.edgeName,
            style: newStyle,
            stateStyles: newStateStyle,
          })
        })
        const mappings = this.rawData.data.subGraphs[subGraphKey].mappings
        const eqvEdges = mappings.map((edge) => {
          const newStateStyle = Object.assign(edge.stateStyles || {}, {
            active: {
              stroke: colorMap.eqvEdgeSubj.activeStroke,
              shadowColor: colorMap.eqvEdgeSubj.activeStroke,
            },
            inactive: {
              fill: colorMap.eqvEdgeSubj.inactiveFill,
              stroke: colorMap.eqvEdgeSubj.inactiveStroke,
            },
            selected: {
              stroke: colorMap.eqvEdgeSubj.selectedStroke,
              shadowColor: colorMap.eqvEdgeSubj.selectedStroke,
            },
            highlight: {
              stroke: colorMap.eqvEdgeSubj.highlightStroke,
            },
            disable: {
              stroke: colorMap.eqvEdgeSubj.disableStroke,
            },
          })
          return Object.assign({}, {
            source: edge.sourceNodeId,
            target: edge.targetNodeId,
            style: Object.assign(edge.style || {}, {
              startArrow: {
                path: 'M 25,0 L 30,2 L 30,-2 Z',
                stroke: colorMap.eqvEdgeSubj.mainStroke,
                fill: colorMap.eqvEdgeSubj.mainStroke,
              },
              endArrow: {
                path: 'M 25,0 L 30,2 L 30,-2 Z',
                stroke: colorMap.eqvEdgeSubj.mainStroke,
                fill: colorMap.eqvEdgeSubj.mainStroke,
              },
              stroke: colorMap.eqvEdgeSubj.mainStroke,
              fill: colorMap.eqvEdgeSubj.mainStroke,
            }),
            stateStyles: newStateStyle,
          })
        })
        graphData.edges = kgEdges.concat(eqvEdges)
        G6.Util.processParallelEdges(graphData.edges)
        graphData.nodes.forEach((i) => {
          i.cluster = i.groupId
          if (i.id === centerNode1 || i.id === centerNode2) {
            i.size = 50
          } else {
            i.size = 50
          }
          let newLabel = ''
          let len = 0
          for (let j = 0; j < i.label.length; ++j) {
            if (len >= 8) {
              newLabel += '\n'
              len = 0
            }
            newLabel += i.label.charAt(j)
            if (i.label.charCodeAt(j) > 255) {
              len += 2
            } else {
              len += 1
            }
          }
          i.label = newLabel
          i.style = Object.assign(i.style || {}, {
            fill: colorMap[i.groupId + 'Subj'].mainFill,
            stroke: colorMap[i.groupId + 'Subj'].mainStroke,
          })
          i.stateStyles = Object.assign(i.stateStyles || {}, {
            active: {
              fill: colorMap[i.groupId + 'Subj'].activeFill,
              stroke: colorMap[i.groupId + 'Subj'].activeStroke,
              shadowColor: colorMap[i.groupId + 'Subj'].activeStroke,
            },
            inactive: {
              fill: colorMap[i.groupId + 'Subj'].inactiveFill,
              stroke: colorMap[i.groupId + 'Subj'].inactiveStroke,
            },
            selected: {
              fill: colorMap[i.groupId + 'Subj'].selectedFill,
              stroke: colorMap[i.groupId + 'Subj'].selectedStroke,
              shadowColor: colorMap[i.groupId + 'Subj'].selectedStroke,
            },
            highlight: {
              fill: colorMap[i.groupId + 'Subj'].highlightFill,
              stroke: colorMap[i.groupId + 'Subj'].highlightStroke,
            },
            disable: {
              fill: colorMap[i.groupId + 'Subj'].disableFill,
              stroke: colorMap[i.groupId + 'Subj'].disableStroke,
            },
          })
        })
        return graphData
      },
      refreshGraph () {
        if (typeof (this.selectedOption) === 'undefined') {
          this.alertMsg = 'WARN: No mapping pair selected for visualization.'
          this.alertType = 'warning'
          this.alertValue = true
          return
        }
        const mappingDataKey = this.subGraphOptionDict[this.selectedOption]
        const graphData = this.mappingData[mappingDataKey]
        this.graph.changeData(graphData)
      },
      downloadImage () {
        if (this.graph) {
          this.graph.downloadFullImage('mapping-pair', 'image/png', {
            padding: [10, 15, 15, 15],
          })
        }
      },
      initGraph () {
        if (this.graph) {
          this.graph.on('node:dragstart', e => {
            // 拖动节点时重新布局
            this.graph.layout()
            this.refreshDragedNodePosition(e)
          })
          this.graph.on('node:drag', e => {
            this.refreshDragedNodePosition(e)
          })
          this.graph.on('node:dragend', e => {
            e.item.get('model').fx = null
            e.item.get('model').fy = null
          })
          this.graph.on('node:mouseenter', (e) => {
            this.graph.setItemState(e.item, 'active', true)
          })
          this.graph.on('node:mouseleave', (e) => {
            this.graph.setItemState(e.item, 'active', false)
          })
          this.graph.on('edge:mouseenter', (e) => {
            this.graph.setItemState(e.item, 'active', true)
          })
          this.graph.on('edge:mouseleave', (e) => {
            this.graph.setItemState(e.item, 'active', false)
          })
        }
      },
    },

  }
</script>
<style lang="css" scoped>
  #kgCanvas {
    margin: 0px 0px;
    height: 500px;
    max-height: 500px;
    padding: 10px 10px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  #wrapper {
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }
  #visual {
    /* min-width: 600px; */
  }
</style>
